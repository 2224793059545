<template>
  <div class="container">
      <iframe :src="routerPath" class="iframe"></iframe>
  </div>
</template>

<script>
  export default {
    name: "home",
    data() {
      return {
        routerPath: 'home/index'
      }
    },
    created() {
      let path = this.$route.query.path;
      if (path){
        this.routerPath = path
        console.log(path)
      }
    }
  }
</script>

<style scoped="scoped">
  .container{
      background: #42b983;
      width: 100%;
      height: 100%;
  }

  .iframe{
    width: 200px;
    height: 100vh;
    border: 0;
    margin: 0 auto;
    display: block;
  }
</style>
<style>
  body{
    width: 100%;
  }
</style>
