<template>
  <div class="settingList">
    <div class="tip" color="#1989fa" background="#ecf9ff" left-icon="info-o">
      注销账号操作，将会清空您账号内的所有信息，包括不限于：
      <br/>1. 账号及身份信息；<br/>2. 问卷及活动参与记录、收益等
      <br/>
      <br/>
      为了您的账号安全，请验证手机号信息
    </div>

    <van-cell-group style="margin-top: 6px">
      <van-field v-model="loginData.mobile" label="手机号" label-width="60px"
                 @input="inputChange" maxlength="11" type="number" center clearable
                 placeholder="请输入当前登录的手机号"/>
    </van-cell-group>

    <van-button round block type="info" :class="{applyBtn:true ,active:isActive}" @click="resetAccount"
                native-type="submit">{{ '注销账号' }}
    </van-button>
  </div>

</template>

<script>
import {Dialog, Toast} from "vant";

  export default {
    name: "changemobile",
    data() {
      return {
        userInfo: {},
        loginData: {
          "mobile": "",
          "smsCode": "",
          "type": 1
        },
        isSendDisabled: false,
        sendCodeButtonText: '获取验证码',
        timeNumber: 60,
        isActive: false,
        isSubmit: false,
      }
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.userInfo)
    },
    methods: {
      sendCode() {
        if (this.loginData.mobile.length == 0) {
          return false;
        }
        this.timer = setInterval(this.doLoop, 1000);
        this.isSendDisabled = true;
        this.$api.user.sendsms({
          "mobile": this.loginData.mobile,
          "type": 1
        }, res => {
          if (res.code == 200) {
            this.isSendDisabled = true;
          } else {
            clearInterval(this.timer);
            this.sendCodeButtonText = '获取验证码';
            this.isSendDisabled = false;
          }
        }, err =>{
          clearInterval(this.timer);
          this.sendCodeButtonText = '获取验证码';
          this.isSendDisabled = false;
        })
      },
      //倒计时开始
      doLoop() {
        this.timeNumber--;
        if (this.timeNumber > 0) {
          this.sendCodeButtonText = "   " + this.timeNumber + 'S';
        } else {
          clearInterval(this.timer); //清除js定时器
          this.isDisabled = false;
          this.sendCodeButtonText = '获取验证码';
          this.timeNumber = 60; //重置时间
        }
      },
      //输入框变化
      inputChange() {
        let mobileLength = this.loginData.mobile.length;
        if (mobileLength == 11) {
          this.isTap(true);
        } else {
          this.isTap(false);
        }
      },
      isTap(off) {
        if (off) {
          this.isSubmit = false;
          this.isActive = true;
        } else {
          this.isSubmit = true;
          this.isActive = false;
        }
      },
      resetAccount(){
        if (this.loginData.mobile != this.userInfo.phone) {
          Toast("手机号不正确，请检查")
          return
        }
        Dialog.confirm({
          title: '提示',
          message: '账号注销后不可恢复，确认注销账号？',
        })
            .then(() => {
              // on confirm
              this.submitReset()
            })
            .catch(() => {
              // on cancel
            });
      },
      submitReset(){
        this.$toast.loading("保存中...")
        this.isSubmit = true

        this.$api.user.resetAccount({}, res => {
          this.$toast.clear()
          this.isSubmit = false
          if (res.code == 200) {
            this.$toast.success("账号已注销")
            localStorage.removeItem('sessionKey')
            localStorage.removeItem('userInfo')
            this.$router.replace("/")
          }else {
            this.$toast.fail(res.msg)
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .settingList {
    width: 100%;
    overflow: hidden;
  }

  .tip {
    font-size: 30px;
    font-weight: bold;
    padding: 16px 16px 30px 30px;
    color: rgba(255, 0, 0, 0.76);
  }

  .formItem {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;

    &.active {
      margin-top: 62px;
    }

    img {
      width: 32px;
      height: 36px;
      margin-right: 20px;
    }

    .phone {
      width: 28px;
      height: 40px;
    }

  }

  .van-cell {
    width: 100%;
  }

  .van-field {
    margin: 0 auto;
    height: 98px;
    color: #333333;
    font-size: 28px;
  }

  .van-field__control {
    text-indent: 30px;
  }

  .van-button {
    background: #fff;
    color: #3E639A;
    font-size: 28px;
    cursor: pointer;
    border: none;
  }

  .applyBtn {
    margin: 312px auto 0;
    width: 594px;
    border: none;
    height: 88px;
    background: #dcdcdc;
    color: #fff;
    cursor: pointer;
    font-size: 32px;
  }

  .applyBtn.active {
    background: #3E639A;
  }
</style>