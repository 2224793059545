import {
  post,
  get
} from '@/until/http'
import {Toast} from "vant";

//  用户相关

class user {
  constructor() {}

  url = {
    wxLogin: '/weixin/api/ma/wxuser/login',
    bindmobile: '/weixin/api/ma/wxuser/bindmobile',
    getAppId: '/weixin/api/ma/wxuser/getAppId',
    getConfig: '/weixin/api/ma/wxuser/getConfig',
    sendsms: 'weixin/api/ma/wxuser/sendsms',
    profile: '/weixin/api/ma/wxuser',
    changeMobile: '/weixin/api/ma/wxuser/changeMobile',
    resetAccount: '/weixin/api/ma/wxuser/logout',
    helpList: '/weixin/api/ma/wxuser/questionList',
    addSample: '/weixin/api/ma/wxuser/addSample',
    bindopenid: '/weixin/api/ma/wxuser/bindopenid'
  }

  // 微信登录
  wxLogin(para, callback) {
    post(this.url.wxLogin, para).then(res => {
      callback(res);
    })
  }
  // 绑定手机号
  bindmobile(para, callback) {
    post(this.url.bindmobile, para).then(res => {
      callback(res);
    })
  }

  //获取 getAppId
  getAppId(para, callback) {
    post(this.url.getAppId, para).then(res => {
      callback(res);
    })
  }

  //发送验证码
  sendsms(para, callback, error) {
    post(this.url.sendsms, para).then(res => {
      callback(res);
    }).catch(err => error(err))
  }

  profile(param, callback){
    get(this.url.profile, param).then(res => {
      callback(res)
    })
  }

  updateProfile(param, callback){
    post(this.url.profile, param).then(res => {
      callback(res)
    })
  }

  updateMobile(param, callback){
    post(this.url.changeMobile, param).then(res => {
      callback(res)
    })
  }

  resetAccount(params, callback){
    get(this.url.resetAccount, params).then(res => {
      callback(res)
    })
  }

  helpList(callback){
    get(this.url.helpList, {}).then(res => {
      callback(res)
    })
  }

  addSample(param,callback){
    const userInfo = JSON.parse(localStorage.userInfo)
    get('/weixin/api/ma/wxuser/refreshToken', {
      userId: userInfo.id
    }).then(res => {
      if (res.code == 200) {
        localStorage.removeItem('isRefreshToken');
        localStorage.setItem('userInfo', JSON.stringify(res.data));
        localStorage.setItem('sessionKey', res.data.sessionKey);
        localStorage.removeItem('isRefreshToken');

        post(this.url.addSample, param).then(res => {
          callback(res)
        })
      } else {
        Toast.fail("登录异常，请重试")
      }
    })
  }

  bindopenid(param,callback){
    post(this.url.bindopenid, param).then(res => {
      callback(res)
    })
  }

  /**
   * 获取 sign、timestamp、appId 等信息
   * @param params 'url': 'https://....'
   * @param callback
   */
  getConfig(params, callback){
    post(this.url.getConfig, params).then(res => {
      callback(res)
    })
  }
}

export default new user();
