<template>
  <div>
    <van-cell style="margin-top: 16px" title="更换手机号" is-link @click="changeMobile()" :value="phone">
      <!--    <template #icon>-->
      <!--      <img class="iconImg" :src="require('@/assets/img/my/can_yu@2x.png')"/>-->
      <!--    </template>-->
    </van-cell>

    <van-cell title="注销账号" is-link @click="resetAccount()"></van-cell>
  </div>

</template>

<script>
  import {Dialog} from "vant";
  import until from "@/until/until";

  export default {
    name: "setting",
    data() {
      return {
        phone: ""
      }
    },
    mounted() {
      this.phone = until.getEncryptPhone(localStorage.userInfo ? JSON.parse(localStorage.userInfo).phone : "")
    },
    methods: {
      changeMobile(){
        this.$router.push('/my/profile/changemobile')
      },
      resetAccount() {
        this.$router.push('/my/profile/reset')
      }
    }
  }
</script>

<style lang="less" scoped>

</style>