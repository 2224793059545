<template>
  <!-- 测试组件 -->
  <div class="test">
    你最喜欢的科目?
    <AnswerOptions :list="list"></AnswerOptions>
  </div>
</template>

<script>
  import AnswerOptions from '@/components/AnswerOptions'
  export default {
    components: { AnswerOptions },
    name: "test",
    data() {
      return {
        list: [{
          id: 1,
          title: '体育',

        }, {
          id: 2,
          title: '美术'
        }, {
          id: 3,
          title: '画画'
        }, {
          id: 4,
          title: '音乐'
        }, {
          id: 5,
          title: '舞蹈'
        }]
      }
    },
    created() {}
  }
</script>

<style scoped="scoped">
  .test {
    padding: 30px;
  }
</style>