// 公共方法

//判断是否是微信环境
const isWechat = () => {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    }
    return false;
}
//
const getUrlParam = (name) => {
    //截取浏览器地址字符串参数
    var result = location.search.match(new RegExp("[\?\&]" + name + "=([^\&]+)", "i"));
    if (result == null || result.length < 1) {
        return "";
    }
    return result[1];
}

//判断json 是否可以被解析
const isJSON = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const getEncryptPhone = (phoneNumber) => {
    // 确保电话号码是有效的（这里假设有效的手机号长度为11位）
    if (phoneNumber.length !== 11) {
        return '****';
    }

    // 使用正则表达式匹配中间4位数字，并替换为****
    return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}

export default {
    isWechat,
    getUrlParam,
    isJSON,
    getEncryptPhone
}
