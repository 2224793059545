<template>
  <!-- 答题组件 -->
  <div class="AnswerOptions">
    <van-checkbox-group v-model="result" @change="checkboxChange">
      <ul>
        <draggable v-model="sortedList" :options="dragOptions" chosen-class='grey-background-class'>
          <TransitionGroup type="transition" tag="ul" name="fade" class="sort-target">
            <li v-for="(item,index) in sortedList" :key="item.id">
              <van-checkbox shape="square" :name="item.id" icon-size="20px">
                <template #icon="props">
                  <div v-if="props.checked" class="checkedIcon">{{index+1}}</div>
                </template>
                {{item.title}}</van-checkbox>
              <div class="my-handle" v-show="sortedList.length>1"><van-icon name="bars" /></div>
            </li>
          </TransitionGroup>
        </draggable>
      </ul>
      <ul>
        <li v-for="item in dataList" :key="item.id">
          <van-checkbox shape="square" :name="item.id" icon-size="20px"> {{item.title}}</van-checkbox>
        </li>
      </ul>
    </van-checkbox-group>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  export default {
    name: "AnswerOptions",
    components: { draggable },
    props: {
      list: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {
        result: [], //选中的数据 可拖拽数据
        dataList: [], //传入数据
        sortedList: [],
        dragOptions: {
          handle: '.my-handle',
        },
      }
    },
    watch: {
      list: {
        handler(n) {
          if (n && n.length > 0) {
            this.dataList = JSON.parse(JSON.stringify(n))
          }
        },
        deep: true,
        immediate: true
      }
    },
    mounted() {},
    methods: {
      checkboxChange(res) {
        this.sortedList = this.list.filter(item => res.includes(item.id))
        this.dataList = this.list.filter(item => !res.includes(item.id))
      },
    }
  }
</script>

<style scoped lang="less">
  .AnswerOptions {
    width: 100%;
    height: auto;
    border: 1px solid #cccccc;
    overflow: hidden;
    border-bottom: 0;

    li {
      display: flex;
      line-height: 30px;
      align-items: center;
      box-sizing: border-box;
      padding: 20px 10px;
      font-size: 30px;
      border-bottom: 1px solid #cccccc;

      .my-handle {
        margin-left: auto;
      }

      &:last-of-type {
        // border-bottom: 0;
      }
    }
  }

  .grey-background-class {
    background: #cccccc;
  }

  .checkedIcon {
    text-align: center;
    background-color: #1989fa;
    color: #ffffff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 25px;
  }

  .fade-move,
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
  }

  .fade-leave-active {
    position: absolute;
  }
</style>